export default [
    {
        name: 'TicketList',
        path: 'products/tickets',
        component: () => import('./views/TicketList.vue'),
        meta: {
            title: 'Tickets',
            perms: 'tickets',
            icon: 'fa-solid fa-receipt',
        },
    },
    {
        name: 'TicketCreate',
        path: 'products/tickets/create',
        component: () => import('./views/TicketForm.vue'),
    },
    {
        name: 'TicketUpdate',
        path: 'products/tickets/:ticket',
        component: () => import('./views/TicketForm.vue'),
    },

    {
        name: 'TicketCategoryList',
        path: 'products/tickets/categories',
        component: () => import('./views/TicketCategoryList.vue'),
        meta: {
            title: 'Ticket Categories',
            perms: 'tickets',
            icon: 'fa-solid fa-receipt',
        },
    },
    {
        name: 'TicketCategoryCreate',
        path: 'products/tickets/categories/create',
        component: () => import('./views/TicketCategoryForm.vue'),
    },
    {
        name: 'TicketCategoryUpdate',
        path: 'products/tickets/categories/:category',
        component: () => import('./views/TicketCategoryForm.vue'),
    },

    {
        name: 'TransportList',
        path: 'products/transports',
        component: () => import('./views/TransportList.vue'),
        meta: {
            title: 'Transports',
            perms: 'tickets',
            icon: 'fa-solid fa-bus-alt',
        },
    },
    {
        name: 'TransportCreate',
        path: 'products/transports/create',
        component: () => import('./views/TransportForm.vue'),
    },
    {
        name: 'TransportUpdate',
        path: 'products/transports/:transport',
        component: () => import('./views/TransportForm.vue'),
    },

    {
        name: 'ProductRequestList',
        path: 'products/tickets/requests',
        component: () => import('./views/ProductRequestList.vue'),
        meta: {
            title: 'Products Requests',
            perms: 'tickets',
            icon: 'fa-solid fa-receipt',
        },
    },
    {
        name: 'ProductRequestUpdate',
        path: 'products/tickets/requests/:requestId',
        component: () => import('./views/ProductRequestForm.vue'),
    },
    {
        name: 'TransportRequestList',
        path: 'products/transports/requests',
        component: () => import('./views/TransportRequestList.vue'),
        meta: {
            title: 'Transports Requests',
            perms: 'tickets',
            icon: 'fa-solid fa-bus-alt',
        },
    },
    {
        name: 'TransportRequestUpdate',
        path: 'products/transports/requests/:requestId',
        component: () => import('./views/TransportRequestForm.vue'),
    },
]
