const MIMETYPES_EXTENSIONS = {
    // Common Types
    'application/pdf': 'pdf',
    'text/csv': 'csv',
    'text/plain': 'txt',

    // Office Types
    'application/msword': 'doc',
    'application/rtf': 'rtf',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.oasis.opendocument.presentation': 'odp',
    'application/vnd.oasis.opendocument.spreadsheet': 'ods',
    'application/vnd.oasis.opendocument.text': 'odt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'pptx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'docx',

    // Archive Types
    'application/gzip': 'gz',
    'application/vnd.rar': 'rar',
    'application/x-7z-compressed': '7z',
    'application/x-bzip': 'bz',
    'application/x-bzip2': 'bz2',
    'application/x-tar': 'tar',
    'application/zip': 'zip',

    // Audio Types
    'application/x-cdf': 'cdf',
    'audio/3gpp': '3gp',
    'audio/3gpp2': '3gp',
    'audio/aac': 'aac',
    'audio/midi': 'midi',
    'audio/mpeg': 'mp3',
    'audio/ogg': 'ogg',
    'audio/opus': 'opus',
    'audio/wav': 'wav',
    'audio/webm': 'webm',
    'audio/x-midi': 'midi',

    // Video Types
    'video/3gpp': '3gp',
    'video/3gpp2': '3gp',
    'video/mp2t': 'mp2t',
    'video/mp4': 'mp4',
    'video/mpeg': 'mpg',
    'video/ogg': 'ogv',
    'video/webm': 'webm',
    'video/x-msvideo': 'avi',

    //Images Types
    'image/bmp': 'bmp',
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/svg+xml': 'svg',
    'image/tiff': 'tif',
    'image/vnd.microsoft.icon': 'ico',
    'image/webp': 'webp',

    // Code Types
    'application/atom+xml': 'xml',
    'application/json': 'json',
    'application/ld+json': 'json',
    'application/x-httpd-php': 'php',
    'application/x-python-code': 'py',
    'application/x-sh': 'sh',
    'application/xhtml+xml': 'html',
    'application/xml': 'xml',
    'text/css': 'css',
    'text/html': 'html',
    'text/javascript': 'js',
    'text/x-python': 'py',
    'text/xml': 'xml',
}

const MIMETYPES_ICONS = {
    // Common Types
    'application/pdf': 'fa-solid fa-file-pdf',
    'text/csv': 'fa-solid fa-file-csv',
    'text/plain': 'fa-solid fa-file-lines',

    // Office Types
    'application/msword': 'fa-solid fa-file-word',
    'application/rtf': 'fa-solid fa-file-word',
    'application/vnd.ms-excel': 'fa-solid fa-file-excel',
    'application/vnd.ms-powerpoint': 'fa-solid fa-file-powerpoint',
    'application/vnd.oasis.opendocument.presentation':
        'fa-solid fa-file-powerpoint',
    'application/vnd.oasis.opendocument.spreadsheet': 'fa-solid fa-file-excel',
    'application/vnd.oasis.opendocument.text': 'fa-solid fa-file-word',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'fa-solid fa-file-powerpoint',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'fa-solid fa-file-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'fa-solid fa-file-word',

    // Archive Types
    'application/gzip': 'fa-solid fa-file-zipper',
    'application/vnd.rar': 'fa-solid fa-file-zipper',
    'application/x-7z-compressed': 'fa-solid fa-file-zipper',
    'application/x-bzip': 'fa-solid fa-file-zipper',
    'application/x-bzip2': 'fa-solid fa-file-zipper',
    'application/x-tar': 'fa-solid fa-file-zipper',
    'application/zip': 'fa-solid fa-file-zipper',

    // Audio Types
    'application/x-cdf': 'fa-solid fa-file-audio',
    'audio/3gpp': 'fa-solid fa-file-audio',
    'audio/3gpp2': 'fa-solid fa-file-audio',
    'audio/aac': 'fa-solid fa-file-audio',
    'audio/midi': 'fa-solid fa-file-audio',
    'audio/mpeg': 'fa-solid fa-file-audio',
    'audio/ogg': 'fa-solid fa-file-audio',
    'audio/opus': 'fa-solid fa-file-audio',
    'audio/wav': 'fa-solid fa-file-audio',
    'audio/webm': 'fa-solid fa-file-audio',
    'audio/x-midi': 'fa-solid fa-file-audio',

    // Video Types
    'video/3gpp': 'fa-solid fa-file-video',
    'video/3gpp2': 'fa-solid fa-file-video',
    'video/mp2t': 'fa-solid fa-file-video',
    'video/mp4': 'fa-solid fa-file-video',
    'video/mpeg': 'fa-solid fa-file-video',
    'video/ogg': 'fa-solid fa-file-video',
    'video/webm': 'fa-solid fa-file-video',
    'video/x-msvideo': 'fa-solid fa-file-video',

    //Images Types
    'image/bmp': 'fa-solid fa-file-image',
    'image/gif': 'fa-solid fa-file-image',
    'image/jpeg': 'fa-solid fa-file-image',
    'image/png': 'fa-solid fa-file-image',
    'image/svg+xml': 'fa-solid fa-file-image',
    'image/tiff': 'fa-solid fa-file-image',
    'image/vnd.microsoft.icon': 'fa-solid fa-file-image',
    'image/webp': 'fa-solid fa-file-image',

    // Code Types
    'application/atom+xml': 'fa-solid fa-file-code',
    'application/json': 'fa-solid fa-file-code',
    'application/ld+json': 'fa-solid fa-file-code',
    'application/x-httpd-php': 'fa-solid fa-file-code',
    'application/x-python-code': 'fa-solid fa-file-code',
    'application/x-sh': 'fa-solid fa-file-code',
    'application/xhtml+xml': 'fa-solid fa-file-code',
    'application/xml': 'fa-solid fa-file-code',
    'text/css': 'fa-solid fa-file-code',
    'text/html': 'fa-solid fa-file-code',
    'text/javascript': 'fa-solid fa-file-code',
    'text/x-python': 'fa-solid fa-file-code',
    'text/xml': 'fa-solid fa-file-code',
}

export const mimetypeExtension = function (value) {
    return MIMETYPES_EXTENSIONS[value] || ''
}

export const mimetypeIcon = function (value) {
    return MIMETYPES_ICONS[value] || 'fa-solid fa-file'
}

export const fullFilename = function (value, mimetype) {
    const ext = mimetypeExtension(mimetype)
    if (ext) {
        return `${value}.${ext}`
    }
    return value
}
