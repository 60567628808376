<template>
    <AutocompleteField
        v-model="$division"
        :label="label"
        right-icon="fa-solid fa-map"
        index="locations_division"
        :errors="errors"
        :filters="filters"
        i18n
    >
        <template #extra="{ item }">
            <div class="extra">
                <span class="tag is-black mr-2">
                    {{ item.country_name }}
                </span>

                <span
                    v-if="item.country_id"
                    :class="`fi fi-${item.country_id.toLowerCase()} mr-3`"
                >
                </span>
            </div>
        </template>

        <template #item="{ item }">
            <div class="columns item-columns p-2">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.name }}</span>
                </div>
                <div class="column"></div>
                <div class="column is-narrow is-vertical-center pr-0 mr-0">
                    <span class="tag is-black">
                        {{ item.country_name }}
                    </span>
                </div>
                <div class="column is-narrow is-vertical-center">
                    <span
                        v-if="item.country_id"
                        :class="`fi fi-${item.country_id.toLowerCase()}`"
                    >
                    </span>
                </div>
            </div>
        </template>
    </AutocompleteField>
</template>

<script>
import AutocompleteField from './AutocompleteField.vue'

export default {
    name: 'T3DivisionAutocompleteField',
    components: {
        AutocompleteField,
    },
    props: {
        modelValue: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'Division',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        countryId: {
            type: String,
            default: '',
        },
    },
    emits: ['update:model-value'],
    computed: {
        $division: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:model-value', newval)
            },
        },
        filters() {
            let result = {}

            if (this.countryId) {
                result.country_id = this.countryId
            }

            return result
        },
    },
}
</script>

<style scoped>
.extra {
    margin-top: 2px;
}
</style>
