<template>
    <div class="field is-fullwidth">
        <label
            v-if="label"
            class="label is-small"
        >
            {{ label }}
        </label>
        <div class="control">
            <T3ModelField
                id="selected_model"
                ref="autocomplete"
                v-model="l_selected_item"
                :endpoint="endpoint"
                :filters="filters"
                :readonly="readonly"
                right-button-icon="fas fa-plus"
                @enter-pressed="on_add_requested"
                @right-button-clicked="on_add_requested"
            >
                <template
                    v-for="(_, slot) of $slots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
            </T3ModelField>
        </div>

        <table
            v-if="l_items.length > 0"
            class="table is-fullwidth"
            :class="{
                'is-hoverable': !readonly,
                'is-bordered': !readonly,
                readonly: readonly,
            }"
        >
            <tbody>
                <tr
                    v-for="(item, index) in l_items"
                    :key="index"
                >
                    <td
                        class="is-size-7 label-cell"
                        v-html="item_html(item.label)"
                    ></td>
                    <td class="actions-cel">
                        <span
                            v-if="!readonly"
                            class="icon is-small has-text-danger"
                            @click="on_item_remove_clicked(item)"
                        >
                            <i class="fa fa-times"></i>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: undefined,
        },
        endpoint: {
            type: String,
            required: true,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:model-value'],
    data: () => ({
        l_items: [],
        l_selected_item: {},
    }),
    watch: {
        modelValue(newval) {
            this.l_items = newval
        },
        l_items: {
            handler: function (newval) {
                this.$emit('update:model-value', newval)
            },
            deep: true,
        },
    },
    mounted() {
        this.l_items = this.modelValue
    },
    methods: {
        on_add_requested() {
            if (this.$isModel(this.l_selected_item)) {
                if (
                    this.l_items.findIndex(
                        (item) => item.pk === this.l_selected_item.pk
                    ) === -1
                ) {
                    this.l_items.push(this.l_selected_item)
                    this.l_selected_item = {}
                    this.$refs.autocomplete.clear()
                }
            }
        },
        on_item_remove_clicked(item) {
            this.l_items = this.l_items.filter((i) => i.pk !== item.pk)
        },
        item_html(value) {
            if (value && value.includes(':')) {
                let vsplit = value.split(':')
                return `${vsplit[0]} <span class="tag is-black is-small">${vsplit[1]}</span>`
            }
            return value
        },
    },
}
</script>

<style scoped>
table {
    border: solid 1px #dbdbdb;
}
tr {
    vertical-align: middle !important;
}
span {
    cursor: pointer;
}
.label-column {
    padding-bottom: 6px;
}
.label-cell {
    border-right: 0 !important;
}
.actions-cel {
    padding: 1px 5px !important;
    border-left: 0 !important;
    vertical-align: middle;
    text-align: right;
}
.readonly {
    background-color: hsl(0deg, 0%, 96%);
}
</style>
