<template>
    <AutocompleteField
        v-model="$hotel"
        :label="label"
        right-icon="fa-solid fa-hotel"
        index="accomodations_hotel"
        :errors="errors"
        :filters="filters"
        add-route="HotelCreate"
        edit-route="HotelUpdate"
        route-param-name="hotel"
        i18n
    >
        <template #extra="{ item }">
            <div
                v-if="item.country_id"
                class="extra"
            >
                <span class="tag is-info is-small mr-1">{{ item.id }}</span>

                <span class="tag is-black mr-2">
                    {{ item.country_name }}
                </span>

                <span :class="`fi fi-${item.country_id.toLowerCase()} mr-3`">
                </span>
            </div>
        </template>

        <template #item="{ item }">
            <div class="columns item-columns p-2">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.name }} {{ stars(item) }}</span>
                </div>
                <div class="column px-0">
                    <span class="tag is-info is-small">{{ item.id }}</span>
                </div>
                <div
                    v-if="item.country_id"
                    class="column is-narrow is-vertical-center pr-0 mr-0"
                >
                    <span class="tag is-black">
                        {{ item.country_name }}
                    </span>
                </div>
                <div
                    v-if="item.country_id"
                    class="column is-narrow is-vertical-center"
                >
                    <span :class="`fi fi-${item.country_id.toLowerCase()}`">
                    </span>
                </div>
            </div>
        </template>
    </AutocompleteField>
    <div class="location">
        <slot name="bottom"></slot>
    </div>
</template>

<script>
import AutocompleteField from './AutocompleteField.vue'

export default {
    name: 'T3HotelAutocompleteField',
    components: {
        AutocompleteField,
    },
    props: {
        modelValue: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'Hotel',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        active: {
            type: Boolean,
            default: undefined,
        },
        countryId: {
            type: String,
            default: '',
        },
        divisionId: {
            type: Number,
            default: 0,
        },
        locationId: {
            type: Number,
            default: 0,
        },
        rating: {
            type: Number,
            default: undefined,
        },
        geolocation: {
            type: Array,
            default: undefined,
        },
        radius: {
            type: Number,
            default: 3.0,
        },
    },
    emits: ['update:model-value'],
    computed: {
        $hotel: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:model-value', newval)
            },
        },
        filters() {
            let result = {}

            if (this.active !== undefined) {
                result.active = this.active ? 1 : 0
            }

            if (this.countryId) {
                result.country_id = this.countryId
            }

            if (this.divisionId) {
                result.division_id = this.divisionId
            }

            if (this.locationId) {
                result.location_id = this.locationId
            }

            if (this.rating) {
                result.rating = this.rating
            }

            if (this.geolocation) {
                result.lng = this.geolocation[0]
                result.lat = this.geolocation[1]
                result.radius = this.radius || 3.0
            }

            return result
        },
    },
    methods: {
        stars(item) {
            switch (item.rating) {
                case 1:
                    return '★'
                case 2:
                    return '★★'
                case 3:
                    return '★★★'
                case 4:
                    return '★★★★'
                case 5:
                    return '★★★★★'
            }

            return ''
        },
    },
}
</script>

<style scoped>
.extra {
    margin-top: 2px;
}
.location {
    margin-top: -10px;
}
</style>
