<template>
    <T3ChoicesField
        v-if="mode === 'combo'"
        v-model="$flag"
        :label="label"
        :readonly="readonly"
        choices="Flag"
    />
    <T3ToggleField
        v-else-if="mode === 'buttons'"
        v-model="$flag"
        :label="label"
        choices="Flag"
    />
</template>

<script>
export default {
    name: 'FlagChoicesField',
    props: {
        modelValue: {
            type: String,
            default: 'unset',
            validator: function (value) {
                return ['unset', 'yes', 'no', null].indexOf(value) !== -1
            },
        },
        label: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'combo',
            validator: function (value) {
                return ['combo', 'buttons'].indexOf(value) !== -1
            },
        },
    },
    emits: ['update:model-value'],
    computed: {
        $flag: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:model-value', newval)
            },
        },
    },
}
</script>
