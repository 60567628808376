<template>
    <T3Modal
        ref="modal"
        :title="title"
    >
        <div ref="searchBox"></div>
        <div
            ref="selectionMap"
            style="width: 600px; height: 300px"
        ></div>
        <template #footer>
            <div class="buttons">
                <T3Button
                    label="Cancel"
                    @clicked="onCancelClicked"
                />
                <T3Button
                    label="Select"
                    role="primary"
                    :disabled="isSelectButtonDisabled"
                    @clicked="onSelectClicked"
                />
            </div>
        </template>
    </T3Modal>
</template>

<script>
const ACCESS_TOKEN =
    'pk.eyJ1IjoicmJlY2VycmExOTg2IiwiYSI6ImNrcmc1ZjdpdjJnbW4ydXA4dGphMnM1YmcifQ.glixiBFMjFElyoQyPcYb5Q'

export default {
    name: 'LocationSelectModal',
    props: {
        title: {
            type: String,
            default: 'Select Location',
        },
    },
    emits: ['placeSelected'],
    data: () => ({
        initialized: false,
        map: null,
        searchBox: null,
        selected: undefined,
        marker: null,
    }),
    computed: {
        isSelectButtonDisabled() {
            return this.selected === undefined
        },
    },
    methods: {
        setMarker(coords) {
            if (this.marker) {
                this.marker.setLngLat(coords)
            } else {
                this.marker = new mapboxgl.Marker()
                    .setLngLat(coords)
                    .addTo(this.map)
            }
        },
        open(nearBy, country) {
            if (typeof country === 'object') {
                country = country.id
            }

            this.$refs.modal.show()
            this.$nextTick(() => {
                if (!this.initialized) {
                    mapboxgl.accessToken = ACCESS_TOKEN

                    this.map = new mapboxgl.Map({
                        container: this.$refs.selectionMap,
                        style: 'mapbox://styles/mapbox/streets-v12',
                        center: [nearBy.lng, nearBy.lat],
                        zoom: nearBy.name !== undefined ? 16 : 10,
                    })
                    this.map.addControl(new mapboxgl.NavigationControl())

                    this.searchBox = new mapboxsearch.MapboxSearchBox()
                    this.searchBox.accessToken = ACCESS_TOKEN
                    this.searchBox.options = {
                        language: 'en',
                        proximity: nearBy,
                        countries: country,
                        types: 'region,district,place,locality,neighborhood,poi',
                    }
                    this.searchBox.mapboxgl = mapboxgl
                    this.searchBox.marker = false

                    this.searchBox.bindMap(this.map)
                    this.searchBox.addEventListener(
                        'retrieve',
                        this.onGeocoderResultClicked
                    )

                    this.$refs.searchBox.appendChild(this.searchBox)

                    this.initialized = true
                } else {
                    const center = [nearBy.lng, nearBy.lat]
                    this.map.setZoom(nearBy.name !== undefined ? 16 : 9)
                    this.map.jumpTo({ center, animate: false, duration: 0 })
                }
            })

            this.$nextTick(() => {
                if (nearBy.name !== undefined) {
                    const coords = [nearBy.lng, nearBy.lat]
                    this.searchBox.value = nearBy.name
                    this.selected = nearBy
                    this.setMarker(coords)
                } else {
                    this.selected = undefined
                }
            })
        },
        onGeocoderResultClicked(event) {
            this.setMarker(event?.detail?.features[0]?.geometry?.coordinates)
            const lng = event?.detail?.features[0]?.geometry?.coordinates[0]
            const lat = event?.detail?.features[0]?.geometry?.coordinates[1]
            this.selected = {
                name: event?.detail?.features[0]?.properties?.name,
                lng,
                lat,
            }
            this.map.jumpTo({ center: [lng, lat], animate: false, duration: 0 })
        },
        onSelectClicked() {
            this.$emit('placeSelected', this.selected)
            this.$refs.modal.hide()
        },
        onCancelClicked() {
            this.$refs.modal.hide()
        },
    },
}
</script>
