import BucketFileField from './BucketFileField.vue'
import CheckField from './CheckField.vue'
import ChoicesField from './ChoicesField.vue'
import DateField from './DateField.vue'
import DateRangeField from './DateRangeField.vue'
import DateTimeField from './DateTimeField.vue'
import MapPointField from './MapPointField.vue'
import ModelField from './ModelField.vue'
import MultipleModelField from './MultipleModelField.vue'
import NumberField from './NumberField.vue'
import RatingField from './RatingField.vue'
import RichTextAreaField from './RichTextAreaField.vue'
import SearchBarField from './SearchBarField.vue'
import SelectToggleField from './SelectToggleField.vue'
import TagsField from './TagsField.vue'
import TextAreaField from './TextAreaField.vue'
import TextField from './TextField.vue'
import TimeField from './TimeField.vue'
import ToggleField from './ToggleField.vue'
import UploadButton from './UploadButton.vue'
import UploadField from './UploadField.vue'
import SwitchField from '@/components/controls/SwitchField.vue'

import I18NTextAreaField from './I18NTextAreaField.vue'
import I18NTextField from './I18NTextField.vue'
import I18NTextRowsField from './I18NTextRowsField.vue'
import LangComboField from './variations/LangComboField.vue'
import LangTabField from './variations/LangTabField.vue'
import MonthsChoicesField from './variations/MonthsChoicesField.vue'
import StaffField from './variations/StaffField.vue'
import YearsChoicesField from './variations/YearsChoicesField.vue'

import AutocompleteField from './autocompletes/AutocompleteField.vue'
import AirportAutocompleteField from './autocompletes/AirportAutocompleteField.vue'
import CountryAutocompleteField from './autocompletes/CountryAutocompleteField.vue'
import DivisionAutocompleteField from './autocompletes/DivisionAutocompleteField.vue'
import LocationAutocompleteField from './autocompletes/LocationAutocompleteField.vue'
import HotelAutocompleteField from './autocompletes/HotelAutocompleteField.vue'

import ActiveAppsChoicesField from './choices/ActiveAppsChoicesField.vue'
import AppsChoicesField from './choices/AppsChoicesField.vue'
import FlagChoicesField from './choices/FlagChoicesField.vue'

import HotelMultipleAutocompleteField from './autocompletes/multiples/HotelMultipleAutocompleteField.vue'

export default function (app) {
    app.component('T3BucketFileField', BucketFileField)
    app.component('T3CheckField', CheckField)
    app.component('T3ChoicesField', ChoicesField)
    app.component('T3DateField', DateField)
    app.component('T3DateRangeField', DateRangeField)
    app.component('T3DateTimeField', DateTimeField)
    app.component('T3MapPointField', MapPointField)
    app.component('T3ModelField', ModelField)
    app.component('T3MultipleModelField', MultipleModelField)
    app.component('T3NumberField', NumberField)
    app.component('T3RatingField', RatingField)
    app.component('T3RichTextAreaField', RichTextAreaField)
    app.component('T3SearchBarField', SearchBarField)
    app.component('T3SelectToggleField', SelectToggleField)
    app.component('T3TagsField', TagsField)
    app.component('T3TextAreaField', TextAreaField)
    app.component('T3TextField', TextField)
    app.component('T3TimeField', TimeField)
    app.component('T3ToggleField', ToggleField)
    app.component('T3UploadButton', UploadButton)
    app.component('T3UploadField', UploadField)
    app.component('T3SwitchField', SwitchField)

    app.component('T3I18NTextAreaField', I18NTextAreaField)
    app.component('T3I18NTextField', I18NTextField)
    app.component('T3I18NTextRowsField', I18NTextRowsField)
    app.component('T3LangComboField', LangComboField)
    app.component('T3LangTabField', LangTabField)
    app.component('T3MonthsChoicesField', MonthsChoicesField)
    app.component('T3StaffField', StaffField)
    app.component('T3YearsChoicesField', YearsChoicesField)

    app.component('T3ActiveAppsChoicesField', ActiveAppsChoicesField)
    app.component('T3AppsChoicesField', AppsChoicesField)
    app.component('T3FlagChoicesField', FlagChoicesField)

    app.component('T3AutocompleteField', AutocompleteField)
    app.component('T3AirportAutocompleteField', AirportAutocompleteField)
    app.component('T3CountryAutocompleteField', CountryAutocompleteField)
    app.component('T3DivisionAutocompleteField', DivisionAutocompleteField)
    app.component('T3LocationAutocompleteField', LocationAutocompleteField)
    app.component('T3HotelAutocompleteField', HotelAutocompleteField)

    app.component(
        'T3HotelMultipleAutocompleteField',
        HotelMultipleAutocompleteField
    )
}
