<template>
    <div class="columns is-multiline">
        <div class="column is-12 label-column">
            <label class="label is-small">Status</label>
        </div>
        <div class="column is-12">
            <div class="select is-small is-multiple w-100">
                <select
                    v-model="$selected"
                    class="w-100"
                    :size="statuses.length"
                    multiple
                >
                    <option
                        v-for="status in statuses"
                        :key="status[0]"
                        :value="status[0]"
                    >
                        {{ status[1] }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        ignore: {
            type: Array,
            default: () => [],
        },
        choices: {
            type: String,
            default: 'LeadStatus',
        },
    },
    emits: ['update:model-value'],
    data: () => ({
        selected: [],
    }),
    computed: {
        statuses() {
            if (this.choices === 'LeadStatus') {
                return this.choicesList('LeadStatus').filter(
                    (x) => !this.ignore.includes(x[0])
                )
            }
            if (this.choices === 'RequestStatus') {
                return this.choicesList('RequestStatus')
            }
            return []
        },
        $selected: {
            get() {
                return this.modelValue || []
            },
            set(newval) {
                this.$emit('update:model-value', newval)
            },
        },
    },
}
</script>
