<template>
    <T3TabPage
        label="Address"
        :errors="pageErrors"
        icon="fa-solid fa-address-card"
    >
        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="modelValue.address_line1"
                    label="Line 1"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="modelValue.address_line2"
                    label="Line 2"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3CountryAutocompleteField
                    v-model="modelValue.address_country"
                    :errors="errors.address_country"
                />
            </div>
            <div class="column">
                <T3DivisionAutocompleteField
                    v-model="modelValue.address_division"
                    :country-id="modelValue.address_country?.id"
                />
            </div>
            <div class="column">
                <T3LocationAutocompleteField
                    v-model="modelValue.address_location"
                    :errors="errors.address_location"
                    :country-id="modelValue.address_country?.id"
                    :division-id="modelValue.address_division?.id"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column is-one-third">
                <T3TextField
                    v-model="modelValue.address_zipcode"
                    label="Zipcode"
                />
            </div>
        </div>

        <div
            v-if="geolocated"
            class="columns"
        >
            <div class="column is-one-third">
                <T3NumberField
                    v-model.number="modelValue.latitude"
                    label="Latitude"
                    :decimals="16"
                />
            </div>
            <div class="column is-one-third">
                <T3NumberField
                    v-model.number="modelValue.longitude"
                    label="Longitude"
                    :decimals="16"
                />
            </div>
        </div>
    </T3TabPage>
</template>

<script>
export default {
    name: 'T3AddressTab',
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        geolocated: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        pageErrors() {
            let result = {}
            if (this.errors.address_country) {
                result.address_country = this.errors.address_country
            }
            if (this.errors.address_location) {
                result.address_location = this.errors.address_location
            }
            return result
        },
        countryId() {
            return (
                this.modelValue.address_country?.pk ||
                this.modelValue.address_country?.id
            )
        },
        divisionId() {
            return (
                this.modelValue.address_division?.pk ||
                this.modelValue.address_division?.id
            )
        },
        divisionCountryId() {
            return this.modelValue.address_division?.country_id
        },
        locationId() {
            return (
                this.modelValue.address_location?.pk ||
                this.modelValue.address_location?.id
            )
        },
        locationCountryId() {
            return this.modelValue.address_location?.country_id
        },
        locationDivisionId() {
            return this.modelValue.address_location?.division_id
        },
    },
    watch: {
        countryId(newval) {
            this.$nextTick(() => {
                if (
                    newval &&
                    this.divisionId &&
                    newval != this.divisionCountryId
                ) {
                    this.modelValue.address_division = null
                }
                if (
                    newval &&
                    this.locationId &&
                    newval != this.locationCountryId
                ) {
                    this.modelValue.address_location = null
                }
            })
        },
        divisionId(newval) {
            this.$nextTick(() => {
                if (
                    newval &&
                    this.locationId &&
                    newval != this.locationDivisionId
                ) {
                    this.modelValue.address_location = null
                }
            })
        },
    },
}
</script>
