<template>
    <div
        :class="{ endpoint }"
        @click="onClicked"
    >
        <span class="icon is-small mr-1">
            <i :class="$mimetypeIcon(mimetype)"></i>
        </span>
        <span>{{ $filename }}</span>
    </div>
</template>

<script>
export default {
    name: 'FileName',
    props: {
        filename: {
            type: String,
            required: true,
        },
        mimetype: {
            type: String,
            default: undefined,
        },
        endpoint: {
            type: String,
            default: undefined,
        },
        maxChars: {
            type: Number,
            default: 16,
        },
    },
    emits: ['click'],
    computed: {
        $filename() {
            if ((this.filename?.length || 0) > this.maxChars) {
                const split = this.filename.split('.')
                if (split.length > 1) {
                    const ext = split[split.length - 1]
                    const name = split
                        .slice(0, split.length - 1)
                        .join('.')
                        .slice(0, this.maxChars - ext.length - 3)
                    return `${name}...${ext}`
                } else {
                    return this.filename.slice(0, this.maxChars)
                }
            }
            return this.filename
        },
    },
    methods: {
        async onClicked() {
            if (this.endpoint) {
                await this.$downloadBucketFile(this.endpoint, this.filename)
            } else {
                this.$emit('click')
            }
        },
    },
}
</script>

<style scoped>
.endpoint {
    cursor: pointer;
}
</style>
