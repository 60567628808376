<template>
    <a
        class="button is-small"
        :class="buttonRole"
        @click="onClick"
    >
        <span
            v-if="buttonIcon"
            class="icon is-small"
        >
            <i :class="buttonIcon"></i>
        </span>
        <template v-if="buttonLabel">{{ buttonLabel }}</template>
    </a>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: undefined,
        },
        icon: {
            type: String,
            default: undefined,
        },
        role: {
            type: String,
            default: undefined,
        },
        uncheckedLabel: {
            type: String,
            default: undefined,
        },
        uncheckedIcon: {
            type: String,
            default: undefined,
        },
        uncheckedRole: {
            type: String,
            default: undefined,
        },
        checkedLabel: {
            type: String,
            default: undefined,
        },
        checkedIcon: {
            type: String,
            default: undefined,
        },
        checkedRole: {
            type: String,
            default: undefined,
        },
    },
    emits: ['update:model-value'],
    computed: {
        buttonLabel() {
            if (this.label) {
                return this.label
            }
            if (this.modelValue && this.checkedLabel) {
                return this.checkedLabel
            } else if (!this.modelValue && this.uncheckedLabel) {
                return this.uncheckedLabel
            }
            return undefined
        },
        buttonIcon() {
            if (this.icon) {
                return this.icon
            }
            if (this.modelValue && this.checkedIcon) {
                return this.checkedIcon
            } else if (!this.modelValue && this.uncheckedIcon) {
                return this.uncheckedIcon
            }
            return undefined
        },
        buttonRole() {
            if (this.role) {
                return `is-${this.role}`
            }
            if (this.modelValue && this.checkedRole && this.checkedRole) {
                return `is-${this.checkedRole}`
            } else if (!this.modelValue && this.uncheckedRole) {
                return `is-${this.uncheckedRole}`
            }
            return undefined
        },
    },
    methods: {
        onClick() {
            this.$emit('update:model-value', !this.modelValue)
        },
    },
}
</script>
