<template>
    <div
        v-for="lang in availableLangs"
        :key="lang"
        class="field"
    >
        <label
            v-if="label !== undefined"
            class="label is-small"
        >
            <span class="icon">
                <i :class="langFlag(lang)"></i>
            </span>
            {{ labelForLang(lang) }}
        </label>

        <div class="control">
            <div class="field">
                <div class="control is-expanded">
                    <input
                        v-model="modelValue[fieldForLang(lang)]"
                        class="input is-small"
                        type="text"
                        :placeholder="placeholder"
                    />
                </div>
            </div>
        </div>
        <p
            v-for="error in errorForLang(lang)"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'
import { defaultLangs, langName, langFlag } from '@/helpers/i18n'

export default {
    name: 'I18NTextField',
    extends: Field,
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        field: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        availableLangs() {
            return defaultLangs()
        },
    },
    methods: {
        fieldForLang(lang) {
            return `${this.field}_${lang}`
        },
        labelForLang(lang) {
            return `${this.label} ${lang.toUpperCase()}`
        },
        errorForLang(lang) {
            const field = this.fieldForLang(lang)
            return this.errors[field] || []
        },
        langName,
        langFlag,
    },
}
</script>
