<template>
    <table
        class="table is-stripped is-narrow is-fullwidth is-size-7 datatable"
        :class="{
            bordered: bordered,
        }"
        :style="{
            paddingTop: hasHeader ? '10px' : '0',
        }"
    >
        <thead class="table-head">
            <tr v-if="$slots['pagination-header']">
                <td
                    style="border: none; background-color: #f9fafb"
                    colspan="100%"
                >
                    <slot name="pagination-header"></slot>
                </td>
            </tr>
            <slot name="dt-header"></slot>
            <tr>
                <th
                    v-if="checkable && checkPosition === 'left'"
                    class="check-column check-column-head"
                    scope="col"
                >
                    <T3CheckField v-model="selectingAll" />
                </th>
                <th
                    v-if="showIndex"
                    class="index-column"
                    scope="col"
                    :style="{
                        width: `${meta.indexWidth}rem`,
                        verticalAlign: 'middle',
                    }"
                >
                    #
                </th>

                <ColumnHeader
                    v-for="field in $fields"
                    :key="field.id"
                    :field="field"
                    :align="field.textAlign"
                    :sortable="field.sortable"
                    @sort="onSortClicked"
                />

                <th
                    v-if="hasItemButtons"
                    class="actions-column actions-column-head"
                    :style="{
                        width: buttonsColumnWidth,
                        textAlign: 'right',
                    }"
                >
                    <ActionsButtons
                        :actions="modelActions"
                        :event-handler="dispatchAction"
                    />
                </th>

                <th
                    v-if="checkable && checkPosition === 'right'"
                    class="check-column check-column-head"
                    scope="col"
                >
                    <T3CheckField v-model="selectingAll" />
                </th>
            </tr>
        </thead>
        <tbody>
            <template
                v-for="(item, index) in $items"
                :key="index"
            >
                <tr
                    scope="row"
                    :style="item.$style"
                >
                    <td
                        v-if="checkable && checkPosition === 'left'"
                        data-label="Checked"
                    >
                        <T3CheckField v-model="item.$checked" />
                    </td>
                    <td
                        v-if="showIndex"
                        class="number-column"
                        data-label="Index"
                    >
                        {{ item.$index }}
                    </td>

                    <td
                        v-for="field in $fields"
                        :key="field.id"
                        :class="field.class"
                        :style="field.style"
                    >
                        <slot
                            v-if="
                                item[field.id] !== null &&
                                item[field.id] !== undefined
                            "
                            :name="`field-${field.id}`"
                            :item="item"
                        >
                            <router-link
                                v-if="field.type === 'link' && field.route"
                                :to="fieldItemRoute(field, item)"
                                :title="
                                    field.formatter(item[field.id], item, field)
                                "
                                :target="field.target"
                            >
                                {{
                                    lengthFilter(
                                        field,
                                        field.formatter(
                                            item[field.id],
                                            item,
                                            field
                                        )
                                    )
                                }}
                            </router-link>
                            <template v-else-if="field.type === 'choices'">
                                {{
                                    lengthFilter(
                                        field,
                                        choicesLabel(
                                            field.choices,
                                            item[field.id]
                                        )
                                    )
                                }}
                            </template>
                            <template v-else-if="field.type === 'bucketfile'">
                                <span class="tag is-info">
                                    <T3FileName
                                        v-if="item[field.id]"
                                        :filename="item[field.id].filename"
                                        :max-chars="32"
                                        @click="
                                            downloadBucketFile(item[field.id])
                                        "
                                    />
                                </span>
                            </template>
                            <template v-else>
                                <span
                                    v-if="!field.formatter"
                                    :title="item[field.id]"
                                >
                                    {{ lengthFilter(field, item[field.id]) }}
                                </span>
                                <span
                                    v-else
                                    :title="
                                        field.formatter(
                                            item[field.id],
                                            item,
                                            field
                                        )
                                    "
                                    v-html="
                                        lengthFilter(
                                            field,
                                            field.formatter(
                                                item[field.id],
                                                item,
                                                field
                                            )
                                        )
                                    "
                                />
                            </template>
                        </slot>
                    </td>

                    <td
                        v-if="hasItemButtons"
                        class="actions-column"
                        data-label="Actions"
                    >
                        <ActionsButtons
                            :actions="$itemActions"
                            :item="item"
                            :event-handler="dispatchAction"
                        />
                    </td>

                    <td
                        v-if="checkable && checkPosition === 'right'"
                        data-label="Checked"
                    >
                        <T3CheckField v-model="item.$checked" />
                    </td>
                </tr>

                <tr
                    v-if="$slots['item-details'] && item.$showDetails"
                    :key="`d${index}`"
                >
                    <td v-if="showIndex"></td>
                    <td
                        :colspan="columnCount - (showIndex ? -1 : 0)"
                        :style="item.$style"
                    >
                        <slot
                            name="item-details"
                            :item="item"
                        >
                        </slot>
                    </td>
                </tr>
            </template>
        </tbody>
        <tfoot>
            <slot name="dt-footer"></slot>
            <tr v-if="$slots['pagination-footer']">
                <td
                    style="
                        border: none;
                        background-color: #f9fafb;
                        padding: 10px 0;
                    "
                    colspan="100%"
                >
                    <slot name="pagination-footer"></slot>
                </td>
            </tr>
        </tfoot>
    </table>
</template>

<script>
import { mapState } from 'pinia'
import { useRoutesStore } from '@/stores/routes'

import Table from './Table'

import ActionsButtons from './parts/ActionsButtons.vue'
import ColumnHeader from './parts/ColumnHeader.vue'

import fieldTypes from './types'

export default {
    components: {
        ActionsButtons,
        ColumnHeader,
    },
    extends: Table,
    props: {
        pkField: {
            type: String,
            default: 'pk',
        },
        items: {
            type: Array,
            default: () => [],
        },
        currentIndex: {
            type: Number,
            default: 0,
        },
        rowStyle: {
            type: [Object, Function],
            default: undefined,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        selectingAll: false,
        meta: {
            indexWidth: 1,
        },
        ordering: null,
        localSorting: null,
    }),
    computed: {
        ...mapState(useRoutesStore, ['routeParams']),
        $fields() {
            return this.fields.map((field) => {
                const type = fieldTypes[field.type || 'string']

                const style = {
                    textAlign: field.align,
                    width: field.width,
                }

                let sortable = true
                if (field.sortable === false) {
                    sortable = false
                }

                return {
                    id: field.id,
                    type: field.type,
                    title: field.label,
                    currency: field.currency,
                    formatter: field.formatter || type.format,
                    style: this.$_.isEmpty(style) ? undefined : style,
                    route: field.route,
                    routePk: field.routePk,
                    choices: field.choices,
                    maxLength: field.maxLength,
                    target: field.target,
                    sortable,
                }
            })
        },
        $fieldsMap() {
            const result = {}
            if (this.$fields) {
                this.$fields.forEach((field) => {
                    result[field.id] = field
                })
            }
            return result
        },
        $items() {
            const result = this.$_.clone(this.items)

            if (this.rowStyle) {
                if (typeof this.rowStyle === 'function') {
                    result.forEach((item) => {
                        item.$style = this.rowStyle(item)
                    })
                } else {
                    result.forEach((item) => {
                        item.$style = this.rowStyle
                    })
                }
            }

            if (this.localSorting) {
                const fieldId = this.localSorting.field
                const inc = this.localSorting.inc
                const valueFunction = this.fieldItemValueFunction(fieldId)
                return result.sort((a, b) => {
                    const va = valueFunction(a[fieldId])
                    const vb = valueFunction(b[fieldId])
                    if (va < vb) {
                        return inc ? -1 : 1
                    } else if (va > vb) {
                        return inc ? 1 : -1
                    }
                    return 0
                })
            }

            return result
        },
        $itemActions() {
            const actions = [...this.itemActions]
            if (this.$slots['item-details']) {
                actions.push({
                    type: 'internal',
                    tooltip: (item) =>
                        item.$showDetails ? 'Hide Details' : 'Show Details',
                    icon: (item) =>
                        item.$showDetails
                            ? 'fas fa-chevron-circle-up'
                            : 'fas fa-angle-down',
                    role: 'info',
                    handler: (item) => {
                        item.$showDetails = item.$showDetails ? false : true
                        this.$forceUpdate()
                    },
                    show: this.itemDetailsShow,
                })
            }
            return actions
        },
        buttonsColumnWidth() {
            let buttonsCount = this.$itemActions.length
            if (this.modelActions.length > buttonsCount) {
                buttonsCount = this.modelActions.length
            }
            return `${buttonsCount * 37 + 2 + buttonsCount - 1}px`
        },
        hasItemButtons() {
            return this.$itemActions.length > 0 || this.modelActions.length > 0
        },
        hasHeader() {
            return this.$slots['dt-header']
        },
    },
    watch: {
        items(newval) {
            let index = this.currentIndex

            for (const item of newval) {
                item.$checked = item.$checked || this.selectingAll
                item.$index = ++index
            }
        },
        selectingAll(newval) {
            for (const item of this.items) {
                item.$checked = newval
            }
        },
        currentIndex(newval) {
            this.meta.indexWidth = `${newval}`.length
        },
    },
    methods: {
        lengthFilter(field, value) {
            if (
                field.maxLength &&
                field.maxLength > 3 &&
                value.length > field.maxLength
            ) {
                return value.substring(0, field.maxLength - 3) + '...'
            }
            return value
        },
        fieldItemRoute(field, item) {
            const route = field.route

            if (typeof route === 'string') {
                const args = this.routeParams(route)
                if (args && args.length > 0) {
                    const params = {}
                    params[args[0]] = this.modelPk(
                        item[field.id],
                        field.routePk || this.pkField
                    )
                    return {
                        name: route,
                        params: params,
                    }
                }
            } else if (typeof route === 'function') {
                return route(item)
            }
            return route
        },
        fieldItemValueFunction(fieldId) {
            const fieldType = this.$fieldsMap[fieldId]?.type || 'string'
            return fieldTypes[fieldType].value
        },
        onSortClicked(sorting) {
            this.localSorting = sorting
        },
        dispatchAction(action, item) {
            this.$emit(action, item)
        },

        modelPk(value, pkName = undefined) {
            const field = pkName || this.pkField

            if (value) {
                if (typeof value === 'object') {
                    return value[field]
                }
                if (typeof value === 'string' || typeof value === 'number') {
                    return value
                }
            }
            return undefined
        },
        async downloadBucketFile(bf) {
            await this.$download(bf.bucket, bf.uid, bf.filename)
        },
    },
}
</script>

<style scoped>
.pagination {
    border-bottom: none !important;
}

.check-column {
    width: 15px;
    padding: 0;
}

.index-column {
    text-align: right !important;
}

.number-column {
    text-align: right;
}

.bordered {
    border: 1px solid #ea621f;
}

.datatable {
    background-color: #f9fafb;
    border-radius: 6px;
    box-shadow:
        0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
        0 0px 0 1px rgba(10, 10, 10, 0.02);
    border-collapse: unset;
    margin-top: 1rem;

    overflow: hidden;
}

.datatable tr {
    background-color: white;
    border: 1px solid #ddd;
    padding: 0.35em;
}

.datatable tbody tr:hover {
    background-color: #f7e4db !important;
}

.datatable tr td:last-child {
    padding-right: 0.3rem !important;
}

.datatable tr td:first-child {
    padding-left: 0.5rem !important;
}

.table-head {
    background-color: #f9fafb !important;
}

.datatable th {
    font-weight: bolder !important;
    color: #ea621f !important;
    text-transform: uppercase;
    border-bottom: 1px solid #ea621f !important;
}
.datatable tbody tr td {
    vertical-align: middle;
    border-top: 1px solid #e5e7eb;
}

.datatable tr:last-child {
    border-bottom: none;
}

.details-row td {
    border-bottom: 0 !important;
}

.actions-column {
    padding-left: 1px !important;
    padding-right: 0px !important;
    text-transform: none !important;
    text-align: right;
    border-top: 1px solid #e5e7eb !important;
}

.actions-column-head {
    background-color: #f9fafb !important;
    padding-right: 0.3rem !important;
    border-top: none !important;
}

.check-column-head {
    vertical-align: middle !important;
    background-color: #f9fafb !important;
}

.datatable thead {
    border: solid 1px #ea621f;
}

.datatable tfoot {
    border: solid 1px #ea621f;
}

.datatable a:hover {
    text-decoration: none !important;
}
</style>
