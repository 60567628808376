<template>
    <T3ChoicesField
        v-model="lang"
        label="Lang"
        :choices="choices"
    />
</template>

<script>
import { defaultLangs, langName } from '@/helpers/i18n'

export default {
    name: 'T3LangComboField',
    props: {
        modelValue: {
            type: String,
            default: 'en',
        },
    },
    emits: ['update:model-value'],
    computed: {
        choices() {
            return defaultLangs().map((lang) => [lang, langName(lang)])
        },
        lang: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:model-value', newval)
            },
        },
    },
}
</script>
