<template>
    <div class="field mb-4">
        <label
            v-if="label"
            class="label is-small mb-4"
        >
            {{ label }}
        </label>
        <div class="columns mb-0 pb-0">
            <div class="column pr-0">
                <slot></slot>
            </div>
            <div class="column pl-0 is-narrow">
                <T3Button
                    role="info"
                    icon="fas fa-plus"
                    :disabled="!canAdd"
                    @click="add"
                />
            </div>
        </div>

        <div
            v-if="modelValue && modelValue.length"
            class="wrapper mt-0 pt-0 mr-1"
        >
            <div
                v-for="(item, idx) in modelValue"
                :key="idx"
                class="columns is-marginless"
            >
                <div class="column is-vertical-center">
                    <slot
                        :item="item"
                        :index="idx"
                        name="item"
                    >
                        {{ item }}
                    </slot>
                </div>
                <div
                    v-if="!readonly"
                    class="column is-narrow is-vertical-center"
                >
                    <span
                        class="icon is-small has-text-danger remove-button"
                        @click="remove(item)"
                    >
                        <i class="fa-solid fa-times"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'T3MultipleAutocompleteField',
    props: {
        selected: {
            type: Object,
            default: undefined,
        },

        modelValue: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:model-value', 'added'],
    computed: {
        canAdd() {
            if (this.selected === undefined) {
                return false
            }

            if (this.selected === null) {
                return false
            }

            const pk = this.selected.id || this.selected.pk

            if (!pk) {
                return false
            }

            const found = this.modelValue.find(
                (item) => item.id === this.selected.id
            )

            if (found) {
                return false
            }

            return true
        },
    },
    methods: {
        add() {
            if (this.canAdd) {
                this.modelValue.push(this.selected)
                this.$emit('update:model-value', this.modelValue)
                this.$emit('added')
            }
        },

        remove(item) {
            const idx = this.modelValue.indexOf(item)
            if (idx !== -1) {
                this.modelValue.splice(idx, 1)
                this.$emit('update:model-value', this.modelValue)
            }
        },
    },
}
</script>

<style scoped>
.wrapper {
    border: solid 1px #dbdbdb;
}

.remove-button {
    cursor: pointer;
}
</style>
