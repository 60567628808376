export default [
    {
        name: 'InboxPage',
        path: 'inbox',
        component: () => import('./views/InboxPage.vue'),
        meta: {
            title: 'Inbox',
            perms: 'leads',
            icon: 'fa-solid fa-inbox',
            hiddenFooter: true,
        },
    },
    {
        name: 'EmailAccountList',
        path: 'emails/accounts',
        component: () => import('./views/EmailAccountList.vue'),
        meta: {
            title: 'Email Accounts',
            perms: 'leads',
            icon: 'fa-solid fa-at',
        },
    },
    {
        name: 'EmailAccountCreate',
        path: 'emails/accounts/create',
        component: () => import('./views/EmailAccountForm.vue'),
    },
    {
        name: 'EmailAccountUpdate',
        path: 'emails/accounts/:account',
        component: () => import('./views/EmailAccountForm.vue'),
    },
    {
        name: 'EmailAnswerList',
        path: 'emails/answers',
        component: () => import('./views/EmailAnswerList.vue'),
        meta: {
            title: 'Email Answers',
            perms: 'leads',
            icon: 'fa-solid fa-comment',
        },
    },
    {
        name: 'EmailAnswerCreate',
        path: 'emails/answers/create',
        component: () => import('./views/EmailAnswerForm.vue'),
    },
    {
        name: 'EmailAnswerUpdate',
        path: 'emails/answers/:answer',
        component: () => import('./views/EmailAnswerForm.vue'),
    },
    {
        name: 'EmailTemplates',
        path: 'emails/templates',
        component: () => import('./views/EmailTemplates.vue'),
        meta: {
            title: 'Email Templates',
            perms: 'crm',
            icon: 'fa-solid fa-envelope-open-text',
        },
    },
    {
        name: 'EmailRuleList',
        path: 'emails/rules',
        component: () => import('./views/EmailRuleList.vue'),
        meta: {
            title: 'Email Rules',
            perms: 'leads',
            icon: 'fa-solid fa-code-fork',
        },
    },
    {
        name: 'EmailRuleCreate',
        path: 'emails/rules/create',
        component: () => import('./views/EmailRuleForm.vue'),
    },
    {
        name: 'EmailRuleUpdate',
        path: 'emails/rules/:rule',
        component: () => import('./views/EmailRuleForm.vue'),
    },
]
