<template>
    <div class="field">
        <label
            v-if="label !== undefined"
            class="label is-small"
            :class="{ 'has-text-danger': (errors || []).length > 0 }"
        >
            {{ labelText }}
        </label>

        <textarea
            v-bind="$attrs"
            :value="modelValue"
            :placeholder="placeholder"
            :rows="rows"
            :disabled="readonly"
            :style="style"
            class="textarea is-small"
            :class="inputClasses"
            @input="onInput"
        >
        </textarea>
        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3TextAreaField',
    extends: Field,
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        rows: {
            type: Number,
            default: 4,
        },
        fullHeight: {
            type: Boolean,
            default: false,
        },
        fullHeightMargin: {
            type: String,
            default: '0px',
        },
    },
    emits: ['update:model-value'],
    computed: {
        style() {
            const result = {}
            if (this.fullHeight) {
                result.height = `calc(100vh - ${this.fullHeightMargin})`
            }
            return result
        },
    },
    methods: {
        onInput(event) {
            this.$emit('update:model-value', event.target.value)
        },
    },
}
</script>
