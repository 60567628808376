<template>
    <div
        ref="searchBox"
        style="width: 100%; height: 50px; box-sizing: border-box"
    ></div>
    <div
        ref="selectionMap"
        :style="`width: 100%; height: calc(100% - 50px);`"
    ></div>
</template>

<script>
const ACCESS_TOKEN =
    'pk.eyJ1IjoicmJlY2VycmExOTg2IiwiYSI6ImNrcmc1ZjdpdjJnbW4ydXA4dGphMnM1YmcifQ.glixiBFMjFElyoQyPcYb5Q'

export default {
    name: 'MapPointField',
    props: {
        latitude: {
            type: Number,
            default: undefined,
        },
        longitude: {
            type: Number,
            default: undefined,
        },
    },
    emits: ['update:latitude', 'update:longitude', 'selectedAddress'],
    data() {
        return {
            map: null,
            searchBox: null,
            currentMarker: null,
        }
    },
    computed: {
        point() {
            if (this.latitude && this.longitude) {
                return [this.longitude, this.latitude]
            }
            return [-4.757000642877298, 36.66221776845058]
        },
    },
    watch: {
        point: {
            handler(newVal) {
                if (newVal[0] && newVal[1]) {
                    this.$nextTick(() => {
                        this.setPoint(newVal)
                    })
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.initMap()

        this.resizeObserver = new ResizeObserver(() => {
            if (this.map) {
                this.map.resize()
                this.setPoint(this.point)
            }
        })
        this.resizeObserver.observe(this.$refs.selectionMap)
    },

    beforeUnmount() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect()
        }
    },
    methods: {
        initMap() {
            mapboxgl.accessToken = ACCESS_TOKEN
            this.map = new mapboxgl.Map({
                container: this.$refs.selectionMap,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: this.point,
                zoom: 16,
            })

            this.searchBox = new mapboxsearch.MapboxSearchBox()
            this.searchBox.accessToken = ACCESS_TOKEN
            this.searchBox.options = {
                language: 'en',
            }

            this.searchBox.mapboxgl = mapboxgl
            this.searchBox.marker = false

            this.searchBox.addEventListener('retrieve', (event) => {
                const [lng, lat] = event.detail.features[0].geometry.coordinates
                this.$emit('update:latitude', lat)
                this.$emit('update:longitude', lng)

                const context = event.detail.features[0]?.properties?.context
                if (context) {
                    this.$emit('selectedAddress', {
                        address_line1: context.address?.name,
                        address_country: {
                            id: context.country?.country_code,
                            label: context.country?.name,
                        },
                        address_zipcode: context.postcode?.name,
                    })
                }
            })

            this.searchBox.bindMap(this.map)

            this.$refs.searchBox.appendChild(this.searchBox)

            this.map.on('click', (e) => {
                this.$emit('update:latitude', e.lngLat.lat)
                this.$emit('update:longitude', e.lngLat.lng)
            })

            this.map.on('load', () => {
                this.map.resize()
            })
        },
        setPoint(point) {
            this.map.jumpTo({
                center: point,
                zoom: 16,
            })

            // Set current marker
            if (this.currentMarker) {
                this.currentMarker.remove()
            }
            this.currentMarker = new mapboxgl.Marker()
                .setLngLat(point)
                .addTo(this.map)
        },
    },
}
</script>
