<template>
    <template
        v-for="action in availableActions"
        :key="action.action"
    >
        <router-link
            v-if="action.type === 'link'"
            :class="action.classes"
            :title="action.tooltip"
            :to="action.to"
            :target="action.target"
        >
            <span
                v-if="action.icon"
                class="icon is-small"
                :class="action.icon"
            ></span>
        </router-link>

        <button
            v-else
            :class="action.classes"
            :title="action.tooltip"
            @click="actionClicked(action, item)"
        >
            <span
                v-if="action.icon"
                class="icon is-small"
                :class="action.icon"
            ></span>
        </button>
    </template>
</template>

<script>
export default {
    props: {
        actions: {
            type: Array,
            default: () => [],
        },
        item: {
            type: Object,
            default: undefined,
        },
        eventHandler: {
            type: Function,
            required: true,
        },
    },
    computed: {
        availableActions() {
            return this.actions
                .filter(
                    (action) =>
                        action.show === undefined || action.show(this.item)
                )
                .map((action) => {
                    let iconClass = action.icon
                    if (typeof iconClass === 'function') {
                        action.icon = iconClass(this.item)
                    }

                    action.classes = [
                        'button',
                        'action',
                        'is-small',
                        'ml-0',
                        'mr-1',
                    ]
                    if (action.role !== undefined) {
                        action.classes.push(`is-${action.role}`)
                    }

                    let extraClasses = action.extraClasses
                    if (extraClasses !== undefined) {
                        if (typeof extraClasses === 'function') {
                            action.classes.push(extraClasses(this.item))
                        } else {
                            action.classes.push(extraClasses)
                        }
                    }

                    let title = action.tooltip
                    if (title !== undefined && typeof title === 'function') {
                        action.tooltip = title(this.item)
                    }

                    if (action.type === 'link') {
                        if (typeof action.route === 'function') {
                            action.to = action.route(this.item)
                        } else if (typeof action.route === 'string') {
                            action.to = {
                                name: action.route,
                            }
                        } else {
                            action.to = action.route
                        }
                    }

                    return action
                })
        },
    },
    methods: {
        handleBucketFile(action, item) {
            let id = action.id
            if (typeof id === 'function') {
                id = id(item)
            }

            let bf = item[id]
            if (bf) {
                if (typeof bf === 'function') {
                    bf = document(item)
                }

                this.$download(bf.bucket, bf.uid, bf.filename)
            }
        },
        handleDownload(action, item) {
            let document = action.document
            let filename = action.filename

            if (typeof filename === 'function') {
                filename = filename(item)
            }

            if (document) {
                if (typeof document === 'function') {
                    document = document(item)
                }
                if (filename) {
                    this.$request(
                        `/t3/documents/${document}/download`,
                        undefined,
                        filename,
                        true
                    )
                } else {
                    this.$request(`/t3/documents/${document}/details`).then(
                        ({ filename }) => {
                            this.$request(
                                `/t3/documents/${document}/download`,
                                undefined,
                                filename,
                                true
                            )
                        }
                    )
                }
            } else {
                let route = action.route

                if (typeof route === 'function') {
                    try {
                        route = route(item)
                    } catch (error) {
                        return undefined
                    }
                } else if (typeof route !== 'string') {
                    throw 'Missing or invalid route argument'
                }

                if (typeof filename !== 'string') {
                    throw 'Missing or invalid filename argument'
                }

                this.$request(route, undefined, filename)
            }
        },
        handleExport(action) {
            this.eventHandler('export', {
                action: action.action,
                filename: action.filename,
            })
        },
        handleInternal(action, item) {
            if (action.handler !== undefined) {
                action.handler(item)
            }
        },
        actionClicked(action, item) {
            switch (action.type) {
                case 'bucketfile': {
                    this.handleBucketFile(action, item)
                    break
                }

                case 'download': {
                    this.handleDownload(action, item)
                    break
                }

                case 'export': {
                    this.handleExport(action)
                    break
                }

                case 'internal': {
                    this.handleInternal(action, item)
                    break
                }

                default: {
                    this.eventHandler(action.action, item)
                }
            }
        },
    },
}
</script>

<style scoped>
.buttons {
    justify-content: flex-end !important;
}
</style>
